import { request } from "../utils/axios-utils";

const namespace = "/api/operation";

export const getTransactionsToClassify = (year) => {
    return request({
        url: namespace + "s/" + year,
        method: "get",
    });
};

export const updateTransaction = (payload: any, _id: string) => {
    console.log(payload);
    return request({
        url: namespace + `s/update`,
        method: "post",
        data: payload,
    });
};

export const linkTransaction = (payload: any) => {
    return request({
        url: namespace + `s/link`,
        method: "post",
        data: payload,
    });
};
