import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import {
    getTransactionsToClassify,
    updateTransaction,
} from "../../../_api/Operation";
import { request } from "../../../utils/axios-utils";
import { getCategories, getFormattedDate } from "../../../utils/tools";
import Loader from "../../layout/Loader";
import InternalTransfersList from "./InternalTransfertList";

const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

export const EBICSTList = () => {
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());

    const [tag, setTag] = useState(0);
    const [transactions, setTransactions] = useState([]);

    const [listOfSubCategories, setListOfSubCategories] = useState<Array<any>>(
        []
    );

    const getTransactions = (_year, _month) => {
        setIsLoading(true);

        getTransactionsToClassify(_year, _month).then((resp) => {
            const txsWithDetection = detectInternalTransfers(resp.data);

            setTransactions(txsWithDetection);
            setIsLoading(false);
        });
    };

    useEffect(() => {
        getTransactions(selectedYear, selectedMonth + 1);
        getSubCategories();
    }, []);

    const getSubCategories = () => {
        request({ addToast, url: `/api/users/subCategories` })
            .then(({ data }) => {
                setListOfSubCategories(data);
                return data;
            })
            .catch();
    };

    const handleCategoryChange = (id, newCategory) => {
        const firstSubCategory =
            listOfSubCategories[newCategory] &&
            listOfSubCategories[newCategory].length > 0
                ? listOfSubCategories[newCategory][0]
                : null;

        const category = getCategories().find(
            (category) => category.value === Number(newCategory)
        );

        setTransactions((prevData) =>
            prevData.map((tx) =>
                tx._id === id
                    ? {
                          ...tx,
                          category: category,
                          sub_category: firstSubCategory.label,
                      }
                    : tx
            )
        );
    };

    const handleSubCategoryChange = (id, newSubCategory) => {
        setTransactions((prevData) =>
            prevData.map((tx) =>
                tx._id === id
                    ? { ...tx, sub_category: { name: newSubCategory } }
                    : tx
            )
        );
    };

    const filteredTransactions = transactions.filter((tx) => {
        // Utiliser transactionsData ici
        const txDate = new Date(tx.date);
        return (
            txDate.getMonth() === selectedMonth &&
            txDate.getFullYear() === selectedYear
        );
    });

    const detectInternalTransfers = (transactions) => {
        const result = [...transactions];

        result.forEach((tx1, index1) => {
            if (tx1.detected_internal_transfer) return; // éviter les doublons
            // montant opposé
            const matchingTx = result.find((tx2, index2) => {
                if (index1 === index2) return false;

                const sameAmount =
                    Math.abs(tx1.amount) === Math.abs(tx2.amount);

                const differentAmount =
                    tx1.mouvement.value !== tx2.mouvement.value;
                const sameProtectedAdult = tx1.majeur_id === tx2.majeur_id;
                const differentAccount = tx1.account_id !== tx2.account_id;

                const sameDate =
                    Math.abs(
                        new Date(tx1.date).setHours(0, 0, 0, 0) -
                            new Date(tx2.date).setHours(0, 0, 0, 0)
                    ) <= 86400000; // ±1 jour

                return (
                    sameAmount &&
                    sameDate &&
                    sameProtectedAdult &&
                    differentAccount &&
                    differentAmount
                );
            });

            if (matchingTx) {
                tx1.detected_internal_transfer = true;
                matchingTx.detected_internal_transfer = true;
            }
        });
        return result;
    };

    const years = Array.from(
        { length: currentYear - 2025 + 1 },
        (_, i) => 2025 + i
    );

    const handleValidate = (id) => {
        const transaction = transactions.find(
            (transaction) => transaction._id === id
        );
        setTransactions((prevData) => prevData.filter((tx) => tx._id !== id));
        updateTransaction(
            {
                operation: {
                    repetition: "_AUCUNE_",
                    files: [],
                    ...transaction,
                },
            },
            transaction._id
        ).then((resp) => {
            console.log(resp.data);
        });
    };

    // Utilitaire pour compter les transactions par mois et année
    const getTransactionCountPerMonth = (transactions, selectedYear) => {
        const counts = Array(12).fill(0); // Tableau pour stocker les comptes des 12 mois

        transactions.forEach((tx) => {
            const date = new Date(tx.date);
            // Vérifier si l'année et le mois correspondent à ceux sélectionnés
            if (date.getFullYear() === selectedYear) {
                counts[date.getMonth()] += 1; // Incrémente le mois approprié (indexé de 0 à 11)
            }
        });

        return counts; // Retourner le tableau avec le nombre de transactions pour chaque mois
    };

    const getTransactionCountsForAllMonths = (transactions, selectedYear) => {
        let transactionCounts = {};

        // Calculer le nombre de transactions pour chaque mois
        const monthlyCounts = getTransactionCountPerMonth(
            transactions,
            selectedYear
        );

        // Ajouter chaque mois au résultat sous un format lisible (Janvier, Février, etc.)
        monthlyCounts.forEach((count, index) => {
            transactionCounts[index] = count;
        });

        return transactionCounts;
    };

    const transactionCounts = getTransactionCountsForAllMonths(
        transactions,
        selectedYear
    );

    return (
        <>
            <div className="mb-4 flex items-center space-x-4">
                <select
                    id="year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                    className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
            </div>

            {isLoading ? (
                <Loader />
            ) : (
                <>
                    {" "}
                    <div className="flex flex-wrap border-b border-gray-200 mb-6">
                        {months.map((month, index) => (
                            <button
                                key={month}
                                onClick={() => {
                                    setSelectedMonth(index);
                                }}
                                className={`relative px-4 py-2 text-sm font-medium border-b-2 ${
                                    selectedMonth === index
                                        ? "text-indigo-600 border-indigo-600"
                                        : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                                }`}
                            >
                                {month}
                                {transactionCounts[index] > 0 && (
                                    <span className="absolute top-0 right-0 -mt-1 -mr-2 bg-indigo-100 text-indigo-600 text-xs px-2 py-0.5 rounded-full">
                                        {transactionCounts[index]}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                    <div className="flex flex-wrap border-b border-gray-200 mb-6">
                        {["Compte a compte", "Toutes les opérations"].map(
                            (month, index) => (
                                <button
                                    key={month}
                                    onClick={() => {
                                        setTag(index);
                                    }}
                                    className={`relative px-4 py-2 text-sm font-medium border-b-2 ${
                                        tag === index
                                            ? "text-indigo-600 border-indigo-600"
                                            : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                                    }`}
                                >
                                    {month}
                                </button>
                            )
                        )}
                    </div>
                    {tag === 0 && (
                        <InternalTransfersList
                            globalTransactions={filteredTransactions}
                        />
                    )}
                    {tag === 1 && (
                        <div className="overflow-x-auto bg-white shadow-md rounded-lg p-4">
                            <table className="min-w-full text-sm text-left text-gray-700">
                                <thead className="bg-gray-100 border-b text-gray-800">
                                    <tr>
                                        <th className="px-4 py-3">Date</th>
                                        <th className="px-4 py-3">Protégé</th>
                                        <th className="px-4 py-3">Compte</th>
                                        <th className="px-4 py-3">Libellé</th>
                                        <th className="px-4 py-3">Montant</th>
                                        <th className="px-4 py-3">Catégorie</th>
                                        <th className="px-4 py-3">
                                            Sous-catégorie
                                        </th>
                                        <th className="px-4 py-3">Source</th>
                                        <th className="px-4 py-3">Document</th>
                                        <th className="px-4 py-3 text-center">
                                            Actions
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredTransactions.length === 0 ? (
                                        <tr>
                                            <td
                                                colSpan="9"
                                                className="px-4 py-6 text-center text-gray-400 italic"
                                            >
                                                Aucune transaction pour ce mois.
                                            </td>
                                        </tr>
                                    ) : (
                                        filteredTransactions.map((tx) => {
                                            return (
                                                <tr
                                                    key={tx.id}
                                                    className="border-b hover:bg-gray-50"
                                                >
                                                    <td className="px-4 py-3">
                                                        {getFormattedDate(
                                                            new Date(tx.date)
                                                        )}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        <span>{tx.name}</span>
                                                    </td>
                                                    <td className="px-4 py-3 font-mono text-xs text-gray-600">
                                                        {tx.account.number}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        {tx.label ||
                                                            "Non renseigné"}
                                                    </td>
                                                    <td className="px-4 py-3 font-medium">
                                                        {tx.mouvement.value ===
                                                            1 && "-"}{" "}
                                                        {tx.amount}€
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        <select
                                                            value={
                                                                tx.category
                                                                    .value
                                                            }
                                                            onChange={(e) =>
                                                                handleCategoryChange(
                                                                    tx._id,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            {getCategories()
                                                                .filter(
                                                                    (cat) =>
                                                                        cat.mvt ===
                                                                        tx
                                                                            .mouvement
                                                                            .value
                                                                )
                                                                .map(
                                                                    (
                                                                        category
                                                                    ) => (
                                                                        <option
                                                                            key={
                                                                                category.label_logiciel
                                                                            }
                                                                            value={
                                                                                category.value
                                                                            }
                                                                        >
                                                                            {
                                                                                category.label
                                                                            }
                                                                        </option>
                                                                    )
                                                                )}
                                                        </select>{" "}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        <select
                                                            key={
                                                                tx._id +
                                                                "sub_catego"
                                                            }
                                                            value={
                                                                tx?.sub_category
                                                                    ?.name
                                                            }
                                                            onChange={(e) =>
                                                                handleSubCategoryChange(
                                                                    tx._id,
                                                                    e.target
                                                                        .value
                                                                )
                                                            }
                                                            className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                                        >
                                                            {listOfSubCategories &&
                                                                listOfSubCategories[
                                                                    tx.category
                                                                        .value
                                                                ].map(
                                                                    (
                                                                        subCategory
                                                                    ) => {
                                                                        return (
                                                                            <option
                                                                                key={
                                                                                    subCategory.key
                                                                                }
                                                                                value={
                                                                                    subCategory.label
                                                                                }
                                                                            >
                                                                                {
                                                                                    subCategory.label
                                                                                }
                                                                            </option>
                                                                        );
                                                                    }
                                                                )}
                                                        </select>{" "}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        {tx.source}
                                                    </td>
                                                    <td className="px-4 py-3">
                                                        <a
                                                            href={tx.fichier}
                                                            download
                                                            title="Télécharger le fichier"
                                                        >
                                                            <i
                                                                className="material-icons"
                                                                style={{
                                                                    fontSize: 16,
                                                                    marginLeft: 5,
                                                                    cursor: "pointer",
                                                                    color: "black",
                                                                }}
                                                            >
                                                                link
                                                            </i>
                                                        </a>
                                                    </td>
                                                    <td className="px-4 py-3 text-center">
                                                        <div className="flex justify-center items-center space-x-2">
                                                            <button
                                                                onClick={() =>
                                                                    handleValidate(
                                                                        tx._id
                                                                    )
                                                                }
                                                                className="bg-green-600 hover:bg-green-700 text-white px-3 py-1 rounded-md text-sm shadow transition"
                                                            >
                                                                Valider
                                                            </button>
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })
                                    )}
                                </tbody>
                            </table>
                        </div>
                    )}
                </>
            )}
        </>
    );
};
