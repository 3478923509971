import React, { useState } from "react";
import ILinkedLabelWithCategory from "../../../interfaces/ILinkedLabelWithCategory";
import InputComponent from "./InputComponent";

const ListLinkLabelsWithCategories: React.FC<{
    remove?: Function;
    list: Array<any>;
    withAmount?: boolean;
    editLinkedLabelWithCategory?: Function;
    updateLinkedLabelWithCategory?: Function;
    linkedLabelWithCategoryInEdit?: ILinkedLabelWithCategory;
    cancel?: Function;
}> = (props) => {
    const [newLinkedLabelWithCategoryValue, setLinkedLabelWithCategoryValue] =
        useState<string>(null);
    const [newAmountValue, setNewAmountValue] = useState<number>(null);

    return (
        <>
            <div className="min-h-[263px] w-full p-2">
                {props.list.map((l: any, index: number) => {
                    return (
                        !props.linkedLabelWithCategoryInEdit &&
                        l !== props.linkedLabelWithCategoryInEdit && (
                            <div
                                key={index}
                                className={`flex justify-between items-center px-4 py-2 ${
                                    !(index % 2) ? "bg-gray-100" : "bg-white"
                                }`}
                            >
                                <span>
                                    {(props.withAmount &&
                                        `${l.label} ${(
                                            l.amount || 0
                                        ).toLocaleString()} €`) ||
                                        l.label}
                                </span>
                                <div className="flex gap-2">
                                    <i
                                        onClick={() => {
                                            props.editLinkedLabelWithCategory(
                                                l
                                            );
                                            setLinkedLabelWithCategoryValue(
                                                l.label
                                            );
                                            setNewAmountValue(l.amount);
                                        }}
                                        className="material-icons text-blue-500 cursor-pointer"
                                    >
                                        edit
                                    </i>
                                    <i
                                        onClick={() => {
                                            props.remove(
                                                (props.withAmount && l) || l
                                            );
                                        }}
                                        className="material-icons text-red-500 cursor-pointer"
                                    >
                                        delete
                                    </i>
                                </div>
                            </div>
                        )
                    );
                })}
                {props.linkedLabelWithCategoryInEdit && (
                    <div className="p-4 bg-yellow-100 rounded-md">
                        <i
                            onClick={() => props.cancel()}
                            className="material-icons text-gray-600 cursor-pointer"
                        >
                            undo
                        </i>
                        <span className="block font-semibold mt-2 mb-4">
                            Modification de la sous catégorie :{" "}
                            {props.linkedLabelWithCategoryInEdit.label}
                        </span>
                        <div
                            className="flex flex-col gap-4"
                            onKeyDown={(
                                event: React.KeyboardEvent<HTMLInputElement>
                            ) => {
                                if (event.key === "Enter") {
                                    props.updateLinkedLabelWithCategory(
                                        newLinkedLabelWithCategoryValue,
                                        newAmountValue,
                                        (props.withAmount &&
                                            props.linkedLabelWithCategoryInEdit) ||
                                            props.linkedLabelWithCategoryInEdit
                                                ._id
                                    );
                                    setLinkedLabelWithCategoryValue(null);
                                }
                            }}
                        >
                            <InputComponent
                                id="idSubCatEditing"
                                className="border border-gray-300 rounded-md p-2 w-full"
                                required
                                value={newLinkedLabelWithCategoryValue}
                                onChange={(e) =>
                                    setLinkedLabelWithCategoryValue(
                                        e.target.value
                                    )
                                }
                                minLength={3}
                                maxLength={50}
                            />

                            {props.withAmount && (
                                <InputComponent
                                    id="idSubCatAmountEditing"
                                    className="border border-gray-300 rounded-md p-2 w-full"
                                    required
                                    value={newAmountValue}
                                    onChange={(e) =>
                                        setNewAmountValue(e.target.value)
                                    }
                                />
                            )}
                            <div>
                                <button
                                    type="button"
                                    className="bg-green-500 hover:bg-green-600 text-white font-semibold py-2 px-4 rounded"
                                    onClick={() => {
                                        props.updateLinkedLabelWithCategory(
                                            newLinkedLabelWithCategoryValue,
                                            newAmountValue,
                                            (props.withAmount &&
                                                props.linkedLabelWithCategoryInEdit) ||
                                                props
                                                    .linkedLabelWithCategoryInEdit
                                                    ._id
                                        );
                                        setLinkedLabelWithCategoryValue(null);
                                    }}
                                >
                                    Modifier
                                </button>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};
export default ListLinkLabelsWithCategories;
