import React, { useState } from "react";

const proteges = [
    { id: "1", nom: "Jean Dupont" },
    { id: "2", nom: "Marie Curie" },
];

const comptesBancaires = {
    "1": [
        {
            id: "compte1",
            iban: "FR7630006000011234567890189",
            banque: "BNP Paribas",
            numeroCompte: "12345678901",
        },
        {
            id: "compte2",
            iban: "FR7630006000019876543210123",
            numeroCompte: "12345678901",
            banque: "Société Générale",
        },
    ],
    "2": [
        {
            id: "compte3",
            iban: "FR7630006000011112223334445",
            banque: "Crédit Agricole",
            numeroCompte: "12345678901",
        },
    ],
};

const fournisseurs = [
    {
        id: "fournisseur1",
        nom: "EDF",
        iban: "FR7630006000011112223334445",
        bic: "QDQFQFQQ",
    },
    {
        id: "fournisseur2",
        nom: "Orange",
        iban: "FR7630006000011112223334445",
        bic: "QFQFQFfsf",
    },
];

const transactions = [
    {
        id: 1,
        protege: "Jean Dupont",
        debitAccount: "FR7630006000011234567890189",
        creditAccount: "FR7630006000011234567890189",
        date: "2025-04-09",
        libelle: "Facture EDF",
        montant: "-78.50 €",
        categorie: "Logement",
        sousCategorie: "Électricité",
        source: "Fintecture",
        fichier: "/docs/facture-edf-avril.pdf",
    },
    {
        id: 2,
        protege: "Marie Curie",
        debitAccount: "FR7630006000011234567890189",
        creditAccount: "FR7630006000011234567890189",
        date: "2025-04-05",
        libelle: "Courses Carrefour",
        montant: "-123.90 €",
        categorie: "Alimentation",
        source: "EBICS",
        sousCategorie: "Supermarché",
        fichier: "/docs/ticket-carrefour.pdf",
    },
];

const months = [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Août",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre",
];

export const EBICSTSList = () => {
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
    const [transactionsData, setTransactionsData] = useState(transactions); // Utiliser transactionsData ici

    const [selectedProtege, setSelectedProtege] = useState("");
    const [selectedCompte, setSelectedCompte] = useState("");
    const [selectedFournisseur, setSelectedFournisseur] = useState("");

    const [formData, setFormData] = useState({
        emetteur: "",
        ibanEmetteur: "",
        bicEmetteur: "",
        beneficiaire: "",
        ibanBeneficiaire: "",
        bicBeneficiaire: "",
        montant: "",
        libelle: "",
        recurrence: false,
        frequence: "mensuelle",
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === "checkbox" ? checked : value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(formData);
    };

    const years = Array.from(
        { length: currentYear - 2025 + 1 },
        (_, i) => 2025 + i
    );

    const getTransactionCountPerMonth = (transactions, selectedYear) => {
        const counts = Array(12).fill(0); // 12 mois

        transactions.forEach((tx) => {
            const date = new Date(tx.date);
            if (date.getFullYear() === selectedYear) {
                counts[date.getMonth()] += 1;
            }
        });

        return counts;
    };

    const filteredTransactions = transactionsData.filter((tx) => {
        // Utiliser transactionsData ici
        const txDate = new Date(tx.date);
        return (
            txDate.getMonth() === selectedMonth &&
            txDate.getFullYear() === selectedYear
        );
    });

    const transactionCounts = getTransactionCountPerMonth(
        transactionsData, // Utiliser transactionsData ici aussi
        selectedYear
    );

    const [isModalOpen, setIsModalOpen] = useState(false); // Pour gérer l'ouverture de la modal

    return (
        <>
            <div className="mb-4 flex justify-between space-x-4">
                {" "}
                <select
                    id="year"
                    value={selectedYear}
                    onChange={(e) => setSelectedYear(parseInt(e.target.value))}
                    className="p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                >
                    {years.map((year) => (
                        <option key={year} value={year}>
                            {year}
                        </option>
                    ))}
                </select>
                <button
                    onClick={() => setIsModalOpen(true)}
                    className="bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700"
                >
                    Créer un ordre de virement
                </button>
            </div>

            {!isModalOpen && (
                <>
                    {" "}
                    <div className="flex flex-wrap border-b border-gray-200 mb-6">
                        {months.map((month, index) => (
                            <button
                                key={month}
                                onClick={() => setSelectedMonth(index)}
                                className={`relative px-4 py-2 text-sm font-medium border-b-2 ${
                                    selectedMonth === index
                                        ? "text-indigo-600 border-indigo-600"
                                        : "text-gray-500 hover:text-gray-700 hover:border-gray-300 border-transparent"
                                }`}
                            >
                                {month}
                                {transactionCounts[index] > 0 && (
                                    <span className="absolute top-0 right-0 -mt-1 -mr-2 bg-indigo-100 text-indigo-600 text-xs px-2 py-0.5 rounded-full">
                                        {transactionCounts[index]}
                                    </span>
                                )}
                            </button>
                        ))}
                    </div>
                    <div className="overflow-x-auto bg-white shadow-md rounded-lg p-4">
                        <table className="min-w-full text-sm text-left text-gray-700">
                            <thead className="bg-gray-100 border-b text-gray-800">
                                <tr>
                                    <th className="px-4 py-3">Date</th>
                                    <th className="px-4 py-3">Protégé</th>
                                    <th className="px-4 py-3">
                                        Compte débiteur
                                    </th>
                                    <th className="px-4 py-3">
                                        Compte créditeur
                                    </th>
                                    <th className="px-4 py-3">Libellé</th>
                                    <th className="px-4 py-3">Montant</th>
                                    <th className="px-4 py-3">Catégorie</th>
                                    <th className="px-4 py-3">
                                        Sous-catégorie
                                    </th>
                                    <th className="px-4 py-3">Source</th>
                                    <th className="px-4 py-3">Document</th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredTransactions.length === 0 ? (
                                    <tr>
                                        <td
                                            colSpan="9"
                                            className="px-4 py-6 text-center text-gray-400 italic"
                                        >
                                            Aucune transaction pour ce mois.
                                        </td>
                                    </tr>
                                ) : (
                                    filteredTransactions.map((tx) => (
                                        <tr
                                            key={tx.id}
                                            className="border-b hover:bg-gray-50"
                                        >
                                            <td className="px-4 py-3">
                                                {tx.date}
                                            </td>
                                            <td className="px-4 py-3">
                                                <span>{tx.protege}</span>
                                            </td>
                                            <td className="px-4 py-3 font-mono text-xs text-gray-600">
                                                {tx.debitAccount}
                                            </td>
                                            <td className="px-4 py-3 font-mono text-xs text-gray-600">
                                                {tx.creditAccount}
                                            </td>
                                            <td className="px-4 py-3">
                                                {tx.libelle}
                                            </td>
                                            <td className="px-4 py-3 font-medium">
                                                {tx.montant}
                                            </td>
                                            <td className="px-4 py-3">
                                                {tx.categorie}
                                            </td>
                                            <td className="px-4 py-3">
                                                {tx.sousCategorie}
                                            </td>
                                            <td className="px-4 py-3">
                                                {tx.source}
                                            </td>
                                            <td className="px-4 py-3">
                                                <a
                                                    href={tx.fichier}
                                                    download
                                                    title="Télécharger le fichier"
                                                >
                                                    <i
                                                        className="material-icons"
                                                        style={{
                                                            fontSize: 16,
                                                            marginLeft: 5,
                                                            cursor: "pointer",
                                                            color: "black",
                                                        }}
                                                    >
                                                        link
                                                    </i>
                                                </a>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>
                    </div>
                </>
            )}

            {isModalOpen && (
                <div className="bg-white p-6 rounded-lg shadow-md">
                    <h2 className="text-2xl font-bold mb-4">
                        Ordre de Virement
                    </h2>
                    <form onSubmit={handleSubmit} className="space-y-4">
                        {/* Champs du formulaire */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Protégé
                            </label>
                            <select
                                name="protege"
                                value={selectedProtege}
                                onChange={(e) => {
                                    setSelectedProtege(e.target.value);
                                    setSelectedCompte("");
                                }}
                                required
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="">
                                    Sélectionnez un protégé
                                </option>
                                {proteges.map((protege) => (
                                    <option key={protege.id} value={protege.id}>
                                        {protege.nom}
                                    </option>
                                ))}
                            </select>
                        </div>

                        {/* Sélection du compte bancaire */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Compte bancaire
                            </label>
                            <select
                                name="compteBancaire"
                                value={selectedCompte}
                                onChange={(e) =>
                                    setSelectedCompte(e.target.value)
                                }
                                required
                                disabled={!selectedProtege}
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500 disabled:bg-gray-100"
                            >
                                <option value="">
                                    Sélectionnez un compte bancaire
                                </option>
                                {selectedProtege &&
                                    comptesBancaires[selectedProtege]?.map(
                                        (compte) => (
                                            <option
                                                key={compte.id}
                                                value={compte.id}
                                            >
                                                {compte.banque} -{" "}
                                                {compte.numeroCompte} -{" "}
                                                {compte.iban}
                                            </option>
                                        )
                                    )}
                            </select>
                        </div>

                        {/* Sélection du fournisseur */}
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Fournisseur
                            </label>
                            <select
                                name="fournisseur"
                                value={selectedFournisseur}
                                onChange={(e) =>
                                    setSelectedFournisseur(e.target.value)
                                }
                                required
                                className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            >
                                <option value="">
                                    Sélectionnez un fournisseur
                                </option>
                                {fournisseurs.map((fournisseur) => (
                                    <option
                                        key={fournisseur.id}
                                        value={fournisseur.id}
                                    >
                                        {fournisseur.nom} - {fournisseur.iban} -{" "}
                                        {fournisseur.bic}
                                    </option>
                                ))}
                            </select>
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Montant
                            </label>
                            <input
                                type="number"
                                name="montant"
                                value={formData.montant}
                                onChange={handleChange}
                                required
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            />
                        </div>
                        <div>
                            <label className="block text-sm font-medium text-gray-700">
                                Libellé
                            </label>
                            <input
                                type="text"
                                name="libelle"
                                value={formData.libelle}
                                onChange={handleChange}
                                className="mt-1 block w-full p-2 border border-gray-300 rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                            />
                        </div>
                        <div className="flex items-center">
                            <input
                                type="checkbox"
                                name="recurrence"
                                checked={formData.recurrence}
                                onChange={handleChange}
                                className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label className="ml-2 block text-sm text-gray-900">
                                Virement récurrent
                            </label>
                        </div>
                        {formData.recurrence && (
                            <div>
                                <label className="block text-sm font-medium text-gray-700">
                                    Fréquence
                                </label>
                                <select
                                    name="frequence"
                                    value={formData.frequence}
                                    onChange={handleChange}
                                    className="mt-1 block w-full p-2 border border-gray-300 bg-white rounded-md shadow-sm focus:ring-indigo-500 focus:border-indigo-500"
                                >
                                    <option value="hebdomadaire">
                                        Hebdomadaire
                                    </option>
                                    <option value="mensuelle">Mensuelle</option>
                                    <option value="annuelle">Annuelle</option>
                                </select>
                            </div>
                        )}
                        <div className="flex">
                            <button
                                onClick={() => setIsModalOpen(!isModalOpen)}
                                className="w-full bg-indigo-600 text-white p-2 rounded-md hover:bg-indigo-700"
                            >
                                Annuler
                            </button>
                            <button
                                type="submit"
                                className="w-full grey text-white p-2 rounded-md hover:bg-indigo-700"
                            >
                                Soumettre
                            </button>
                        </div>
                    </form>
                </div>
            )}
        </>
    );
};
