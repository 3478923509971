import React, { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";
import { linkTransaction } from "../../../_api/Operation";
import { getFormattedDate } from "../../../utils/tools";

const InternalTransfersList = (props) => {
    const { globalTransactions } = props;
    const [pairs, setPairs] = useState([]);
    const { addToast } = useToasts();

    useEffect(() => {
        const matchedPairs = detectTransferPairs(globalTransactions);
        setPairs(matchedPairs);
    }, [globalTransactions]);

    const detectTransferPairs = (transactions) => {
        const pairs = [];
        const used = new Set();

        transactions.forEach((tx1, i) => {
            if (used.has(tx1._id)) return;

            const tx2 = transactions.find((tx2) => {
                if (tx1._id === tx2._id || used.has(tx2._id)) return false;

                const sameAmount =
                    Math.abs(tx1.amount) === Math.abs(tx2.amount);

                const differentAmount =
                    tx1.mouvement.value !== tx2.mouvement.value;
                const sameProtectedAdult = tx1.majeur_id === tx2.majeur_id;
                const differentAccount = tx1.account_id !== tx2.account_id;

                const sameDate =
                    Math.abs(
                        new Date(tx1.date).setHours(0, 0, 0, 0) -
                            new Date(tx2.date).setHours(0, 0, 0, 0)
                    ) <= 86400000; // ±1 jour
                return (
                    differentAmount &&
                    sameAmount &&
                    sameProtectedAdult &&
                    differentAccount &&
                    sameDate
                );
            });

            if (tx2) {
                used.add(tx1._id);
                used.add(tx2._id);
                pairs.push([tx1, tx2]);
            }
        });

        return pairs;
    };

    const handleValidate = (tx1, tx2) => {
        tx1.cpt_cible._id = tx2.account._id;
        tx1.cpt_cible.linked_operation_id = tx2._id;
        tx1.mouvement.name =
            Number(tx1.mouvement.value) === 0
                ? "Cpt à Cpt Crédit"
                : "Cpt à Cpt Débit";
        tx1.mouvement.value = Number(tx1.mouvement.value) === 0 ? 2 : 3;

        tx1.cpt_cible.number = tx2.account.number;
        tx1.cpt_cible.type = tx2.account.type;
        tx1.sub_category.name = "";
        tx1.category.mvt = "";
        tx1.category.name = "";
        tx1.category.value = "";

        tx2.cpt_cible._id = tx1.account._id;
        tx2.cpt_cible.linked_operation_id = tx1._id;
        tx2.cpt_cible.number = tx1.account.number;
        tx2.cpt_cible.type = tx1.account.type;
        tx2.mouvement.name =
            Number(tx2.mouvement.value) === 0
                ? "Cpt à Cpt Crédit"
                : "Cpt à Cpt Débit";
        tx2.mouvement.value = Number(tx2.mouvement.value) === 0 ? 2 : 3;

        tx2.sub_category.name = "";
        tx2.category.mvt = "";
        tx2.category.name = "";
        tx2.category.value = "";

        linkTransaction({ tx1, tx2 }).then((resp) => {
            addToast(resp.data.message, { appearance: "success" });
        });
        setPairs((prev) =>
            prev.filter(([a, b]) => a._id !== tx1._id && b._id !== tx2._id)
        );
    };

    return (
        <div className="p-6 bg-white rounded shadow">
            <h2 className="text-lg font-semibold mb-4">
                Opérations compte à compte détectées
            </h2>
            {pairs.length === 0 ? (
                <p className="text-gray-500 italic">
                    Aucune opération détectée.
                </p>
            ) : (
                <table className="w-full table-auto text-sm">
                    <thead>
                        <tr className="text-left border-b">
                            <th className="p-2">Débit</th>
                            <th className="p-2">Crédit</th>
                            <th className="p-2">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pairs.map(([debit, credit]) => (
                            <tr
                                key={debit._id + credit._id}
                                className="border-b"
                            >
                                <td className="p-2">
                                    <div>
                                        {getFormattedDate(new Date(debit.date))}
                                    </div>
                                    <div>{debit.label}</div>
                                    <div className="font-bold text-red-600">
                                        -{debit.amount}€
                                    </div>
                                </td>
                                <td className="p-2">
                                    <div>
                                        {getFormattedDate(
                                            new Date(credit.date)
                                        )}
                                    </div>
                                    <div>{credit.label}</div>
                                    <div className="font-bold text-green-600">
                                        +{credit.amount}€
                                    </div>
                                </td>
                                <td className="p-2">
                                    <button
                                        className="bg-indigo-600 hover:bg-indigo-700 text-white px-3 py-1 rounded-md text-sm shadow"
                                        onClick={() =>
                                            handleValidate(debit, credit)
                                        }
                                    >
                                        Valider le lien
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            )}
        </div>
    );
};

export default InternalTransfersList;
